import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    actions,
    getters,
    state() {
      return {
        _env: env,
        _modals: [],
        _menus: [],
        _types: {
          // Типы получения
          RECEIVE_PICKUP: 1, // Самовывоз
          RECEIVE_DELIVERY: 2, // Доставка
          // Типы квантов
          UNIT_GRAM: 1, // граммы
          UNIT_PIECE: 2, // штуки
          // Типы оплат
          PAYMENT_ONLINE: 1,
          PAYMENT_OFFLINE: 2,
          // Типы времени работы магазинов
          WORK_SCHEDULE: 1, // Работает по графику
          WORK_ALL_DAY: 2, // Работает круглосуточно
          // Типы документов
          ACT_PROCESSING_PERSONAL_DATA: 1,
        },
        _statuses: {
          // Статусы для заказов
          ORDER_CREATED: 1, // заказ создан
          ORDER_PREPARING: 2, // заказ на сборке
          ORDER_ASSEMBLED: 3, // собрано
          ORDER_IN_DELIVERY: 4, // в доставке
          ORDER_FINISHED: 5, // доставлен
          ORDER_CANCELED: 6, // отменен
          // Статусы продуктов в заказе
          PRODUCT_WAIT: 1, // Собрать
          PRODUCT_ASSEMBLED: 2, // Товар собран
          PRODUCT_CANCELED: 3, // Товар отменен
          // PRODUCT_CLARIFY: 3, // Уточнить
        },
        _overlays: {
          searchOverlay: false,
          mobileSearchOverlay: false,
          catalogOverlay: false,
        },
        _loading_types: {
          PAGINATION: 1,
          LOAD_MORE: 2,
        },
        auth: {
          user: null,
          cart: [],
          client_delivery_info: {},
          my_cards: [],
          my_addresses: [],
          my_orders: [],
          points: null,
        },
        auth_token: null,
        guest_id: null,
        shops: [],
        tmp: {},
        variables: {}, // данные для фильтра
        global: {
          // глобальные данные, нужные на каждой странице
          shops: [],
          socials: [],
          header_documents: [],
          footer_documents: [],
          company_documents: [],
          partners_documents: [],
          for_customers_documents: [],
          parent_categories: [],
        },
        home_page: {
          banners: [],
          product_sets: [],
          cook_links: [],
          hot_links: [],
          products: {},
          discount_products: {}, // товары со скидкой
          cook_products: [], // кулинарные товары
          stocks_cooking_groups: [], // Между акциями и кулинарией
          cooking_sets_groups: [], // После группы “Кулинария” и до списка наборов
          after_sets_groups: [], // После списка наборов
        },
        static_page: {},
        catalog_page: {
          discount_products: {}, // товары со скидкой
          product_sets: [],
          parent_categories: [],
          cook_links: [],
          products: [],
          brands: [],
          banner: {},
          important_categories: [], // важные категории
        },
        category_main_page: {
          category: {},
          // discount_products: [], // продукты по акции
        },
        sets_page: {
          set_groups: [],
        },
        set_group_page: {},
        set_item_page: {},
        not_found_page: {
          discount_products: [],
        },
        blog_open_page: null,
        blog_page: {
          categories: [],
          activeCategories: null,
          blogs: [],
          page: 1,
          first: 13,
          total: null,
          loader: false,
        },
        category_open_page: {
          brands: [],
          countries: [],
          products: {
            data: [],
            paginatorInfo: {},
          },
          category: {},
          product_group: {},
          hot_links: [],
          categories: [],
          discount_products: [],
          child_categories: [],
        },
        order_page: {
          use_points: null,
          promo_code_percent: null,
          promo_code_sale_value: null,
          express: false,
        },
        promo_products: [],
        activePromoCode: null,
        cart_loading: false,
      };
    },
  });
}
